export function useSidebarVisibility(visible: boolean | Ref<boolean> = true) {
	const refVisible = useState('sidebar:visible', () => unref(visible));

	function toggle() {
		refVisible.value = !refVisible.value;
	}

	function hide() {
		refVisible.value = false;
	}

	function show() {
		refVisible.value = true;
	}

	return { visible: readonly(refVisible), toggle, hide, show };
}
